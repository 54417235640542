<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">厉害了，国内首个环境损害鉴定评估标准在福建省开始实施了！</div>
      <p class="time">2018年3月25日，</p>
      <div class="time" style="margin-top: -20px">国内首个环境损害鉴定评估标准，</div>
      <div class="time" style="margin-top: -20px">在福建省正式实施！</div>


      <p class="space_content">
        ● 福建省环境保护厅提出
      </p>
      <p class="space_content" style="margin-top: -20px">
        ● 福建省质量技术监督局发布
      </p>
      <p class="space_content" style="margin-top: -20px">
        ● 标准由《环境损害鉴定评估通用规范》及土壤、大气、地表水、森林等环境要素的分项损害鉴定评估技术方法组成
      </p>
      <p class="space_content" style="margin-top: -20px">
        ● 其中《环境损害鉴定评估通用规范》作为环境损害鉴定评估的基本要求,是国内在该领域的首个标准
      </p>
      <p class="space_content" style="margin-top: -20px">
        ●《环境损害鉴定评估通用规范》由福建历思司法鉴定所负责起草，厦门市环境监测中心站、泉州师范学院、厦门环境科学研究院、三明市环境保护科学研究所、莆田市环境监测中心站参加起草
      </p>

      <p class="space_content titleText" style="font-size: 21px"> 为什么要制定标准？</p>
      <p class="space_content titleText" style="color: red">1、保护环境 · 生态发展</p>
      <p class="space_content">
        面对当今资源约束趋紧、环境污染严重、生态系统退化的严峻形势，必须树立尊重自然、顺应自然、保护自然的生态文明理念，走可持续发展道路。
      </p>
      <p class="space_content titleText" style="color: red">2、环境法治 · 损害赔偿</p>
      <p class="space_content">
        环保问题作为与人民生活息息相关的民生问题，需要在依法治国的框架下采用法律的手段解决。
      </p>
      <p class="space_content">
        <span style="font-weight: 600">
          污染入刑：
        </span>
        2016年12月23日，最高人民法院、最高人民检察院（“两高”）发布了《最高人民法院、最高人民检察院关于办理环境污染刑事案件适用法律若干问题的解释》（法释[2016]29号）（“新司法解释”），新司法解释已于2017年1月1日起实施。
      </p>
      <p class="space_content">
        <span style="font-weight: 600">
          损害赔偿：
        </span>
        中共中央办公厅
        国务院办公厅印发《生态环境损害赔偿制度改革方案》提出，自2018年1月1日起，在全国试行生态环境损害赔偿制度。到2020年，力争在全国范围内初步构建责任明确、途径畅通、技术规范、保障有力、赔偿到位、修复有效的生态环境损害赔偿制度。
      </p>
      <p class="space_content titleText" style="color: red">3、司法鉴定 · 法治基石</p>
      <p class="space_content">
        在环境案件的司法裁决中，环境损害鉴定评估意见是诉讼证据链的关键环节，也是支撑依法处置环境污染和惩治环境犯罪的有力依据。环境损害鉴定评估是环境法治不可或缺的基石。
      </p>
      <p class="space_content titleText" style="color: red">4、公正公平 · 标准先行</p>
      <p class="space_content">
        要维护公正公平，提高证据效能，环境损害鉴定评估必须遵循科学的方法和严格的程序，制定相关标准和规范意义重大。
      </p>

      <p class="space_content titleText" style="font-size: 21px"> 厉害了！我的福建</p>
      <p class="space_content titleText" style="color: red">1、全国首个生态文明试验区</p>
      <p class="space_content">
        福建“依山傍海”，九成陆地面积为山地丘陵地带，被称为“八山一水一分田”，森林覆盖率达65.95%，居全国第一。天高海阔，美丽清新。
      </p>
      <p class="space_content">
        福建省是党的十八大以来，国务院确定的全国第一个生态文明先行示范区，在环境保护工作上基础良好、表现突出。
      </p>
      <p class="space_content titleText" style="color: red">2、先行先试 意义深远/p>
        <p class="space_content">
          福建省作为建设生态文明试点省份之一，省委省政府在闽委发〔2014〕21号中明确提出建立逐步建立生态环境损害赔偿制度。建立福建省相关环境损害鉴定评估地方标准对于我省生态文明制度建设具有现实意义。
        </p>
        <p class="space_content">
          虽然近年来环境保护部陆续颁布了《环境损害鉴定评估推荐方法（第Ⅱ版）》（2014）、《生态环境损害鉴定评估技术指南
          总纲》（2016）、《生态环境损害鉴定评估技术指南
          损害调查》（2016）等规范，但仍未形成标准。同时，这些规范主要作原则性规定，细化程度不足；与执法、司法实践接轨不够，证据化程度不足。福建省在环境损害鉴定评估标准上的先行先试，可填补国内空白，意义重大、影响深远。
        </p>

        <p class="space_content titleText" style="font-size: 21px"> 面向证据，亮点满满</p>
        <p class="space_content titleText" style="color: red">1、证据效能原则</p>
        <p class="space_content" style="color:red;">
          环境损害鉴定评估的根本目的是为环境保护和对环境损害行为进行处置提供有效能的证据（具备证据能力与证据证明力）。
        </p>
        <p class="space_content">
          围绕这一目的，环境损害鉴定评估机构及其工作人员应树立起严格的证据意识，在环境证据的勘验提取上，重视其时效性、针对性、客观性、规范性和关联性。在专业分析判断中，注重理论方法和基础数据的鉴别使用，严密推理论证过程，严格审核基础资料，完善工作程序和审批流程，以审慎的科学态度得出鉴定评估意见，从而最大程度地降低机构出具报告文书的排除机会，更好的为环境案件的仲裁、执法和司法等活动服务。。
        </p>

        <p class="space_content titleText" style="color: red">2、《环境损害鉴定评估通用规范》</p>
        <p class="space_content titleText" style="color: red">三大看点</p>
        <p class="space_content" >
          《环境损害鉴定评估通用规范》着眼于弥补目前国内环境损害鉴定评估方法在证据形成的可操作性和与地方环境保护实践结合上的不足，在《生态环境损害鉴定评估技术指南
          总纲》、《环境损害鉴定评估推荐方法（第Ⅱ版）》、《突发环境事件应急处置阶段环境损害评估推荐方法》和《司法鉴定程序通则》等国内现有标准、方法及法规的基础上，从以下三个方面进行了完善
        </p>
        <p class="space_content" >
          一是
          <span style="color: red">接轨</span>
          ，通过构建更为严谨的术语体系与操作程序，使之与司法、执法实践充分接轨；
        </p>
        <p class="space_content" >
          二是
          <span style="color: red">证据化</span>
          ，从证据形成的角度分清职责、梳理逻辑、厘清概念、规范表述，使鉴定评估过程更具客观性与可操作性；
        </p>
        <p class="space_content" >
          三是
          <span style="color: red">体系化</span>
          ，以通用规范作为环境损害鉴定评估的基本要求，与按生态环境要素分类的其他细化技术方法相衔接，构成较为完整的技术标准体系，使鉴定评估活动更为科学有序。

        </p>
    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/11'"><p class="ellipsis1">
            科学仪器为您揭开古玩艺术品的真伪</p></router-link>

        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/13'"><p class="ellipsis1">
            涉嫌违法污染环境 — 厦门海沧非法炼油厂被端！</p></router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
